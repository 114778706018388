import React from 'react';
import PropTypes from 'prop-types';

import withRoot from '../withRoot';
import Layout from '../components/Layout';
import IntroText from '../components/IntroText';
import Notes from '../components/Notes';
import DealHeadersRow from '../components/DealHeadersRow';
import Meta from '../components/Meta';
import PromptBoxes from '../components/PromptBoxes';
import TopicHeader from '../components/TopicHeader';

import TimeFrameTopic from '../topics/time-frame';
import NHSTopic from '../topics/nhs';
import TravelTopic from '../topics/travel';
import GroceriesTopic from '../topics/groceries';
import EconomyTopic from '../topics/economy';
import EuFundingTopic from '../topics/eu-funding';
import IrishBorderTopic from '../topics/irish-border';
import TradeTariffsTopic from '../topics/trade-tarrifs';
import EuRulesTopic from '../topics/eu-rules';
import DivorceBillTopic from '../topics/divorce-bill';
import NonEuMigrationTopic from '../topics/non-eu-migration';
import EuMigrationTopic from '../topics/eu-migration';
import WelfareTopic from '../topics/welfare';
import StrengthOfPoundTopic from '../topics/strength-of-pound';
import GdpTopic from '../topics/gdp';
import PassportTopic from '../topics/passport';
import UK2EUContributionTopic from '../topics/uk-eu-contribution';
import EU2UKContributionTopic from '../topics/eu-uk-contribution';
import FreeMovementTopic from '../topics/free-movement';
import EnvironmentTopic from '../topics/environment';
import VATTopic from '../topics/vat';
import SovereigntyTopic from '../topics/sovereignty';
import AgricultureTopic from '../topics/agriculture';
import TradeTopic from '../topics/trade';

import LabCustomsUnion from '../topics/lab-customs-union';
import LabSingleMarket from '../topics/lab-single-market';
import LabDynamicProtections from '../topics/lab-dynamic-protections';
import LabEUAgencies from '../topics/lab-eu-agencies';
import LabSecurity from '../topics/lab-security';

import LabCollaborate from '../topics/lab-collaborate';
import LabSameBenefits from '../topics/lab-same-benefits';
import LabMigration from '../topics/lab-migration';
import LabRaceToBottom from '../topics/lab-race-to-bottom';
import LabNationalSecurity from '../topics/lab-national-security';
import LabRegions from '../topics/lab-regions';

import { OutboundLink } from 'gatsby-plugin-google-analytics';

const Link = (({ href, children }) => (
  <OutboundLink
    href={href}
    target="_blank"
    rel="noopener noreferrer"
  >
    {children}
  </OutboundLink>
));

const ViewAll = ({ classes, className, location }) => (

  <Layout location={location}>
    <Meta location={location} title="All issues" />
    <IntroText />
    <TopicHeader title="All issues" />
    <DealHeadersRow />
    <TimeFrameTopic />
    <NHSTopic />
    <GroceriesTopic />
    <DivorceBillTopic />
    <EconomyTopic />
    <NonEuMigrationTopic />
    <EuMigrationTopic />
    <WelfareTopic />
    <FreeMovementTopic />
    <SovereigntyTopic />
    <IrishBorderTopic />
    <EuFundingTopic />
    <TravelTopic />
    <PassportTopic />
    <TradeTariffsTopic />
    <StrengthOfPoundTopic />
    <TradeTopic />
    <GdpTopic />
    <VATTopic />
    <EuRulesTopic />
    <UK2EUContributionTopic />
    <EU2UKContributionTopic />
    <EnvironmentTopic />
    <AgricultureTopic />
    <PromptBoxes/>
    <Notes/>
  </Layout>
);

ViewAll.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object.isRequired,
};

ViewAll.defaultProps = {
  data: {},
};

export default withRoot(ViewAll);
