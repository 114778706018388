import React from 'react';

import { OutboundLink } from 'gatsby-plugin-google-analytics';

import Topic from '../components/Topic';

import { ReactComponent as TimeFrameIcon } from '../assets/clock.svg';

const topic = {
  "title": "Time Frame",
  "results": {
    "deal": {
      "status": "amber",
      "comment": "Boris deal: Leave in 2021 - May deal: Leave in a 21 month transition period"
    },
    "disorderly": {
      "status": "red",
      "comment": "After leaving with no deal, lengthy Brexit negotiations would begin"
    },
    "remain": {
      "status": "green",
      "comment": "Article 50 can be revoked at any time and would put an immediate end to all Brexit proceedings"
    },
    "reform": {
      "status": "green",
      "comment": "Article 50 can be revoked at any time and would put an immediate end to all Brexit proceedings"
    }
  }
};

const TimeFrameTopic = () => (
  <Topic topic={topic} icon={TimeFrameIcon}>
    <p>
      If we leave with no deal then every past trade deal, law, regulation,
      licence, etc that we had as part of the EU will need recreating or
      renegotiating. This will take a huge amount of time. Trade deals for
      example are known for taking years to negotiate and then implement.
      But it's not trade deals alone. The UK operates within the EU for many
      things other than trade. Once we leave the EU, many licences will be
      invalid. If we leave with no deal, this will only be the start of Brexit.
    </p>
    <p>
      In addition to years of disruption described above, a no deal Brexit is
      also likely to cause a lot of political disturbances such as resignations
      and snap general elections.
    </p>
    <p>
      The withdrawal agreement (May's deal) included a 21 month transition
      period where the UK would be able to leave the EU but stay within the
      customs union until the end of the 21 month period where it would then
      leave the customs union also. These two stages would reduce the impact of
      leaving the EU in one go, but many negotiations would still need to take
      place within that period.
    </p>
    <p>
      Boris Johnson's deal states that we would leave on 1st January 2021. <OutboundLink href="https://www.theguardian.com/politics/2019/oct/02/two-borders-four-years-what-boris-johnson-brexit-offer">(source)</OutboundLink>
    </p>
    <p>
      Article 50 can be revoked by the UK and end all Brexit proceedings.
      This is likely to be an option in a second referendum ("people's vote").
    </p>
  </Topic>
);

export default TimeFrameTopic;
